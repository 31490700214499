import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { SmoothScrollService } from '../services/smooth-scroll.service';
import * as locationsJson from './../../assets/data/locations.json'

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {

  locationsArray = [];

  getLocationImageUrl(id) {
    return `linear-gradient(180deg, transparent 25%, #212121b3), url('./../../assets/img/locations/${this.locationsArray[id].imgUrl}')`;
  }

  constructor(private router: Router,
              private smoothScroll: SmoothScrollService,
              private dataService: DataService) { }

  ngOnInit(): void {
    // this.locationsArray = Object.values(locationsJson['default']);
    this.dataService.getLocations().subscribe(
      response => {
        console.log(response);
        this.locationsArray = Object.values(response);
      }
    );
  }

  scroll() {
    this.smoothScroll.smoothVerticalScrolling('anchor', 800);
  }

  goTo(subpath) {
    if (subpath) {
      this.router.navigate(['locations', subpath]);
    }
  }

}
